"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.filterValidTransactions = void 0;
/**
 * Returns an array of transactions where txn.isError is not true.
 * This does not care about sender/reciever relationship (see filterValidOutgoingTransactions)
 */
const filterValidTransactions = (params) => {
    return params.transactions.reduce((prev, txn) => {
        var _a;
        // since response is sorted, we only need to compare hash of preceeding valid txn
        if ((params.isContract || // contracts include incoming
            txn.from.toLowerCase() === params.address.toLowerCase()) && // normal address only include outgoing
            ((_a = prev[prev.length - 1]) === null || _a === void 0 ? void 0 : _a.hash) !== txn.hash) {
            return prev.concat([txn]);
        }
        return prev;
    }, []);
};
exports.filterValidTransactions = filterValidTransactions;
