"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.calculateEmissions = void 0;
const filterValidTransactions_1 = require("./utils/filterValidTransactions");
const getSumGasUsed_1 = require("./utils/getSumGasUsed");
const getAddressTransactions_1 = require("./utils/getAddressTransactions");
const validateCalculatorOptions_1 = require("./utils/validateCalculatorOptions");
/**
 * Based on 2021 methodology, see github and carbon.fyi for details.
 * Last updated: June 24, 2021
 */
const KG_CO2_PER_GAS = 0.0001809589427;
const calculateEmissions = (options) => __awaiter(void 0, void 0, void 0, function* () {
    var _a, _b, _c, _d;
    (0, validateCalculatorOptions_1.validateCalculatorOptions)(options);
    const { isContract = false, address } = options;
    const { transactions, done } = yield (0, getAddressTransactions_1.getAddressTransactions)(options);
    const filteredTransactions = (0, filterValidTransactions_1.filterValidTransactions)({
        isContract,
        transactions,
        address,
    });
    const gasUsed = (0, getSumGasUsed_1.getSumGasUsed)(filteredTransactions);
    return {
        kgCO2: Math.round(gasUsed * KG_CO2_PER_GAS),
        transactionsCount: filteredTransactions.length,
        gasUsed,
        highestBlockNumber: Number((_b = (_a = transactions[0]) === null || _a === void 0 ? void 0 : _a.blockNumber) !== null && _b !== void 0 ? _b : "0"),
        lowestBlockNumber: Number((_d = (_c = transactions[transactions.length - 1]) === null || _c === void 0 ? void 0 : _c.blockNumber) !== null && _d !== void 0 ? _d : "0"),
        done,
    };
});
exports.calculateEmissions = calculateEmissions;
