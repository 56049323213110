<template>
  <b-container class="my-5">

    <div v-if="getActiveAccount" class="row">
      <div class="col-md-6 offset-md-3 text-center">
        <h1>Retire CO2</h1>
        <p>By retiring your ZCO2 tokens you are demonstrating that your wallet has used these tokens to offset
          carbon
          emissions.</p>

        <div v-if="CO2TonsUsed != null && getLockedTokenBalance != null" class="d-none">
          <p>
            This address has made {{ CO2TonsUsed.transactionsCount }} transactions on ETH consuming {{
              new Intl.NumberFormat('de-DE', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0
              }).format(CO2TonsUsed.gasUsed)
            }} gas.<br>
            In total, this address is responsible for {{
              new Intl.NumberFormat('de-DE', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              }).format(CO2TonsUsed.kgCO2 / 1000)
            }} Tones of CO₂ emissions.
          </p>

          <b-alert v-if="getLockedTokenBalance >= CO2TonsUsed.kgCO2 / 1000" class="d-none" show
                   variant="success">
            <h4>This Wallet is Eco friendly</h4>
            <img alt="" class="d-block mx-auto mt-2"
                 src="@/assets/img/eco.png"
                 style="width: 100px">
          </b-alert>
          <b-alert v-else class="d-none" show variant="danger">
            <h4>This Wallet is not Eco friendly</h4>
            <img alt="" class="d-block mx-auto mt-2"
                 src="@/assets/img/contaminated.png" style="width: 100px">
          </b-alert>
        </div>


        <!--                <button @click="tokens = CO2TonsUsed.kgCO2 / 1000" class="btn btn-secondary btn-sm mb-4">Offset CO₂ emissions</button>-->


        <b-card>

          <div class="mb-3" role="group">
            <label for="wallet-address">Your wallet:</label>
            <b-form-input
              id="wallet-address"
              v-model="getActiveAccount"
              aria-describedby="input-live-help input-live-feedback"
              disabled
              trim
            ></b-form-input>
          </div>


          <div class="mb-3" role="group">
            <label for="balance">Tokens to retire:</label>
            <b-input-group>
              <b-form-input
                id="balance"
                v-model="tokens"
                aria-describedby="input-live-help input-live-feedback"
                min="0"
                placeholder="0.0"
                trim
                type="number"
              ></b-form-input>
              <b-input-group-append>
                <b-button variant="primary" @click="setMaxTokens">Max</b-button>
              </b-input-group-append>
            </b-input-group>
            <small class="form-text text-muted">Balance: {{ truncate(getTokenBalance, 4) }}</small>
          </div>
          <b-button variant="primary" @click="lock">Retire</b-button>
          <a v-if="getLockedTokenBalance" :href="getCertUrl+'/'+getWeb2ActiveAccount.id"
             class="btn btn-secondary ml-2"
             target="_blank">View certificate</a>

        </b-card>

        <!--        <h1>Forests</h1>-->

        <!--        <MapBoxFullComponent v-if="areas" :areas="areas"/>-->


        <!--    <AreaListComponent v-if="areas" :items="areas" :search="true" :pagination="true" />-->
      </div>
    </div>
    <div v-else class="text-center">
      <h1>MISSING WALLET</h1>
      <p>You need to connect your wallet in order to use this feature.</p>
      <router-link class="btn btn-primary mt-3 m-auto" tag="button" to="/dashboard">Connect</router-link>
    </div>
  </b-container>
</template>

<script>
// import MapBoxFullComponent from '../components/Area/MapBoxFullComponent'
// import AreaListComponent from '../components/Area/AreaListComponent'
// import Area from "../api/Area"

import {mapActions, mapGetters} from "vuex";
import Swal from "sweetalert2";
import {calculateEmissions} from "../../vendors/ethereum-emissions-calculator";
import Retire from "@/api/Retires";

export default {
  name: 'LockTokens',

  components: {
    // MapBoxFullComponent,
    // AreaListComponent
  },
  computed: {
    ...mapGetters("user", ["getWeb2ActiveAccount"]),
    ...mapGetters("accounts", ["getActiveAccount"]),
    ...mapGetters("tokenContract", ["getTokenBalance"]),
    ...mapGetters("lockedTokenContract", ["getLockedTokenBalance"]),

    certificateImg() {
      return require('../assets/img/certificate.png')
      // The path could be '../assets/img.png', etc., which depends on where your vue file is
    },
    getCertUrl() {
      return process.env.VUE_APP_CERT_URL
    }
  },
  watch: {
    getActiveAccount() {
      this.main();
    }
  },

  data() {
    return {
      tokens: null,
      CO2TonsUsed: null
    }
  },

  created() {
    this.main();
  },

  methods: {

    ...mapActions("tokenContract", ["updateBalance"]),
    ...mapActions("lockedTokenContract", ["lockTokens", "updateLockedBalance"]),
    main() {
      if (this.getActiveAccount)
        calculateEmissions({
          address: this.getActiveAccount,
          etherscanAPIKey: '1IWREX1I9PJ2YZQ3QMX9HR6GF45KXRREQJ',
          isContract: false
        }).then((CO2TonsUsed) => {
          this.CO2TonsUsed = CO2TonsUsed;
          console.log({CO2TonsUsed})
        })
    },
    setMaxTokens() {
      this.tokens = this.truncate(this.getTokenBalance, 4);
    },
    truncate(num, decimals) {
      if (!num) num = 0;
      let re = new RegExp('^-?\\d+(?:.\\d{0,' + (decimals || -1) + '})?');
      return num.toString().match(re)[0];

    },
    async lock() {
      console.log('typeof this.tokens', typeof this.tokens);
      console.log('this.tokens', this.tokens);
      if (!parseFloat(this.tokens)) {
        Swal.fire(
          'Error',
          `The amount of tokens to retire can't be 0.`,
          'error'
        )
        return;
      }


      Swal.fire({
        title: 'Processing',
        html: 'Waiting for confirmations',
        didOpen: () => {
          Swal.showLoading()
        },
        allowOutsideClick: () => !Swal.isLoading()
      })

      this.lockTokens(this.tokens).then((tx) => {


        Retire.postRetires({
          wallet_address: this.getActiveAccount,
          amount: this.tokens,
          contract_address: tx.to,
          tx: tx.hash,
          user: this.getWeb2ActiveAccount.id,
        }).then(() => {
          Swal.fire(
            'CO2 successfully retired',
            `You've successfully retire ${this.tokens} CO2 tons.<br>
<a href="${this.getCertUrl}/${this.getWeb2ActiveAccount.id}" target="_blank">View certificate</a><br>
<a download="zerticarbon-certificate.png" class="d-none" href="${this.certificateImg}">
    Download certificate image
</a>
`,
            'success');

          console.log({tx});
          this.tokens = 0;
          this.updateLockedBalance();
          this.updateBalance();
        })

      })
        .catch((e) => {
          console.error(e, 'e2')
          Swal.fire({
            title: 'Error',
            html: e.message || e,
            icon: 'error',
            didOpen: () => {
              Swal.hideLoading()
            },
          })
        })
    },
    async offset() {
      const tokensToOffset = this.CO2TonsUsed.kgCO2 / 1000 - this.getLockedTokenBalance;

      if (tokensToOffset) {
        Swal.fire(
          'Error',
          `The amount of tokens to retire can't be 0.`,
          'error'
        )
        return;
      }

      this.tokens = tokensToOffset > 0;

      Swal.fire({
        title: 'Processing',
        html: 'Waiting for confirmations',
        didOpen: () => {
          Swal.showLoading()
        },
        allowOutsideClick: () => !Swal.isLoading()
      })

      this.lockTokens(this.tokens).then((tx) => {
        Swal.fire(
          'CO2 successfully retired',
          `You've successfully retire ${this.tokens} CO2 tons.`,
          'success');

        console.log({tx});
        this.tokens = 0;
        this.updateLockedBalance();
        this.updateBalance();
      })
        .catch((e) => {
          console.log(e, 'e2')
          Swal.fire({
            title: 'Error',
            html: e.message,
            icon: 'error',
            didOpen: () => {
              Swal.hideLoading()
            },
          })
        })

    },
  }
}
</script>

<style scoped>

</style>
