import Ajax from './Ajax.js'

const Retire = {
  getRetires: (id = null) => {
    let uri = id !== null
      ? '/retires' + '/' + id
      : '/retires'
    return Ajax.get(uri)
  },

  postRetires: (params) => {
    return Ajax.post('/retires', params)
  },

  putRetires: (params) => {
    return Ajax.put('/retires/' + params.id, params)
  },

  delRetires: (id) => {
    return Ajax.delete('/retires/' + id)
  }
}

export default Retire
