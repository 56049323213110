"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getAddressTransactions = void 0;
const fetchTransactions_1 = require("./fetchTransactions");
const ETHERSCAN_RESULT_MAX_LENGTH = 10000;
/**
 * Fetch the most recent transactions, descending order (highest block # first)
 * Etherscan will return max 10k, so if we exceed this, we filter out the lowest block number (oldest)
 * i.e. this will return ALL transactions in a given block, or none.
 */
const getAddressTransactions = (options) => __awaiter(void 0, void 0, void 0, function* () {
    const transactions = yield (0, fetchTransactions_1.fetchTransactions)(options);
    if (transactions.length < ETHERSCAN_RESULT_MAX_LENGTH) {
        return { done: true, transactions };
    }
    const lowestBlockNumber = transactions[transactions.length - 1].blockNumber;
    return {
        done: false,
        transactions: transactions.filter(
        // filter out the lowest block number because we don't know if we captured all of that blocks txns.
        (txn) => txn.blockNumber > lowestBlockNumber),
    };
});
exports.getAddressTransactions = getAddressTransactions;
