"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.constructEtherscanURL = exports.ETHERSCAN_API_URL = void 0;
exports.ETHERSCAN_API_URL = "https://api.etherscan.io/api";
const constructEtherscanURL = (options) => {
    var _a, _b;
    const { address, etherscanAPIKey, startBlock, endBlock, isContract, } = options;
    const etherscanURL = new URL(exports.ETHERSCAN_API_URL);
    const action = "txlist";
    const params = {
        action,
        module: "account",
        sort: "desc",
        startBlock: (_a = startBlock === null || startBlock === void 0 ? void 0 : startBlock.toString()) !== null && _a !== void 0 ? _a : "0",
        endBlock: (_b = endBlock === null || endBlock === void 0 ? void 0 : endBlock.toString()) !== null && _b !== void 0 ? _b : "99999999",
        apikey: etherscanAPIKey,
    };
    for (const property in params) {
        etherscanURL.searchParams.append(property, params[property]);
    }
    if (action !== "txlist" && isContract) {
        etherscanURL.searchParams.append("contractaddress", address);
    }
    else {
        etherscanURL.searchParams.append("address", address);
    }
    return etherscanURL.toString();
};
exports.constructEtherscanURL = constructEtherscanURL;
